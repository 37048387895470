.homePreviewsRow {
  :global(.cs-section-title) {
    .intro {
      color: #aaa;
    }

    .viewAll {
      color: #3488bf !important;
      text-transform: none !important;
      font-size: 15px !important;
    }
  }
}
