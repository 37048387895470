@import '../../theme/variables.scss';

.home {
  .banner {
    position: relative;
    background-image: url('../../assets/img/home_background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px 0 40px;

    @media screen and (max-width: 375px) {
      background-image: url('../../assets/img/home_background_mobile.webp');
    }

    @media screen and (max-width: 480px) {
      /* height: 450px; */
      padding: 25px 0;
    }
  }

  :global(.slick-slider) {
    margin-bottom: 50px;

    :global(.courses-grid) {
      margin-bottom: 10px;
    }
  }

  :global(.cs-column-text) {
    .tagline {
      font-size: 58px !important;
      color: #ffffff !important;
      line-height: 64px !important;
      text-transform: capitalize !important;
      margin-bottom: 20px;
      text-align: center;
    }

    .subtag {
      font-size: 36px !important;
      line-height: 42px !important;
      color: #fff !important;
      font-weight: 400 !important;
      margin-bottom: 30px;
      text-align: center;
    }

    .inputBox {
      width: 200px;
      height: 51px;
      border-radius: 50px;
      text-align: center;
    }

    .inputBox::-webkit-input-placeholder {
      text-align: center;
    }

    .inputBox:-moz-placeholder {
      /* Firefox 18- */
      text-align: center;
    }

    .inputBox::-moz-placeholder {
      /* Firefox 19+ */
      text-align: center;
    }

    .inputBox:-ms-input-placeholder {
      text-align: center;
    }

    .searchBtn {
      font-size: 13px;
      font-weight: 700;
      line-height: 19px;
      padding: 16px 20px;
      border-radius: 50px;
      color: #fff;
      text-decoration: none;
      outline: none;
      border: none;
      margin-left: 5px;
    }

    .donation {
      color: #fff;
    }

    :global(.call-actions) {
      padding: 25px 30px;
      margin-top: 20px;

      :global(.cell.icon) {
        @media screen and (max-width: 480px) {
          padding: 0;
        }
      }

      @media screen and (max-width: 480px) {
        text-align: center;
      }
    }
  }

  :global(.cs-section-title) {
    .intro {
      color: #aaa;
    }

    .viewAll {
      color: #3488bf !important;
      text-transform: none !important;
      font-size: 15px !important;
    }
  }

  :global(.cs-top-categories) {
    img {
      width: 50px;
      height: 50px;
      margin-top: 20px;
    }
  }
}

.button {
  display: inline-flex;
  height: 72px;
  width: 230px;
  border: 3px solid #3488bf;
  color: #000000;
  margin: 20px 20px 20px 20px;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 1px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  background: linear-gradient(to right, #3488bf 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

.button:hover {
  background-position: left bottom;
  text-decoration: none;
  color: rgb(248, 242, 242);
}

@media only screen and (max-width: 375px) {
  .button {
    width: 180px;
  }
}

.partner {
  font-family: 'Caveat', sans-serif;
  font-size: 42px;
  color: #000;
  line-height: 1.3;
}

.partnerImg {
  text-align: center;

  img {
    height: 230px;
  }

  @media only screen and (min-width: 1200px) {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 1200px) {
  .mainButton {
    margin-left: 13%;
  }
}

:global(.cs-section-title.center) {
  .community {
    font-size: 30px;
    font-family: 'Caveat', sans-serif;
  }
}

.news {
  font-size: 28px;
  line-height: 36px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  color: #207dba;

  p {
    font-size: 14px;
    font-weight: normal;
    color: black;
  }
}

.title {
  font-weight: bold;
  font-size: 18px;
}

.morePartners {
  color: #3488bf !important;
  font-size: 18px !important;
  font-weight: bolder;
  letter-spacing: 0.05em;
}
